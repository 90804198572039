// ** Initial user ability
export const initialAbility = [
  {
    action: 'read',
    subject: 'Auth',
  },
  {
    action: 'read',
    subject: 'calendar',
  },
];

export const _ = undefined;
